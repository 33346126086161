.trusted_by_industry {
  padding: 90px 0px 56px;
  text-align: center;
}
.trusted_by_industry_color {
  padding: 0 56px 90px 56px;
  text-align: center;
  background: #fffcfa;
}

.trusted_by_industry_p {
  color: #304054;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  letter-spacing: -0.3px;
  margin-bottom: 20px !important;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.marquee_block {
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.marquee {
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  user-select: none;
  gap: 20px;
}

.marquee__inner {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
  min-width: 100%;
  animation: marquee 25s linear infinite;
}

.mq_data {
  width: auto;
  overflow: hidden;
  display: inline-flex;
  margin-right: 30px;
  align-items: center;
  gap: 10px;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-100% - 20px));
  }
}

@media screen and (max-width: 767px) {
  .trusted_by_industry {
    padding: 40px 0px 26px;
  }

  .trusted_by_industry_p {
    font-size: 14px;
    line-height: 114.286%;
  }
}
